export const comparisonInfo = (hubblePricing) => {
  return(
    {
      daily: {
        classic: true,
        hydro: true,
        skyhy: true
      },
      eco: {
        classic: true,
        hydro: true,
        skyhy: true
      },
      natural: {
        classic: false,
        hydro: true,
        skyhy: true
      },
      blink: {
        classic: false,
        hydro: true,
        skyhy: true
      },
      world: {
        classic: false,
        hydro: true,
        skyhy: false
      },
      touch: {
        classic: false,
        hydro: true,
        skyhy: false
      },
      high_oxygen: {
        classic: false,
        hydro: false,
        skyhy: true
      },
      flex_wear: {
        classic: false,
        hydro: true,
        skyhy: true
      },
      top_choise: {
        classic: false,
        hydro: false,
        skyhy: true
      },
      // fda: {
      //   classic: true,
      //   hydro: true,
      //   skyhy: true
      // },
    }
  )
}

export const labels = {
  pack: "pack size",
  price: "first order price",
  fda: "Fda-approved",
  personalized: "personalized delivery cadence",
  daily: {text: "daily disposables", sub: 'Keep your eyes healthy with fresh lenses everyday'},
  monthly: {text: "monthly disposable", sub: 'An affordable way to benefit from  the latest lens technology'},
  eco: {text: "Packaging made from recyclable material"},
  natural: {text: "Natural Eye Hydration", sub: "Material and shape are specially designed to retain moisture on the lens surface"},
  blink: {text: "Blink Comfort Design", sub: "Lens design minimizes discomfort when blinking"},
  world: {text: "World's slimmest lens packaging", sub: "Less plastic, less space, more convenient"},
  touch: {text: "Hygienic Touch Technology", sub: "Packaged so that your fingers never touch the inside of the lens"},
  high_oxygen: {text: "High Oxygen PERMEABILITY", sub: "Cutting-edge silicone hydrogel material allows oxygen to move through the lens for fresh eyes all day"},
  flex_wear: {text: "Soft flex wear", sub: "Flexible construction allows lenses to mold to the curve of your eye, fitting snugly and comfortably over the surface"},
  top_choise: {text: "Doctors’ top choice", sub: "7 out of 10 new prescriptions are for lenses made of SkyHy's material (silicone hydrogel)"},
}

export const contactLenses = (isClassicNewPrice, hubblePricing, isOtp) => {
  return(
    [
      isClassicNewPrice
      ? {title: "Hubble<sup>®</sup> Classic Daily", feature: "affordable dailies", type: "classic", product: "Classic", price: "<b>$1.00</b> <br/><span class='weak'>10-day starter pack</span>", originalPrice: "$22.99/eye", tagText:'our originals', tagColor: 'charged-blue', packSize: 30, link: "/contact-lenses/classic-by-hubble-lenses", cadence: 28}
      : {title: "Hubble<sup>®</sup> Classic Daily", feature: "affordable dailies", type: "classic", product: "Classic", price: "<b>$1.00</b> <br/><span class='weak'>10-day starter pack</span>", originalPrice: "$19.99/eye", tagText:'our originals', tagColor: 'charged-blue', packSize: 30, link: "/contact-lenses/classic-by-hubble-lenses", cadence: 28},
      {title: "Hydro by Hubble<sup>®</sup> Daily", feature: "naturally hydrating", type: "hydro", product: "Hydro", price: `$${hubblePricing.hydro.firstPrice}/eye`, originalPrice: `$${hubblePricing.hydro.subPrice}/eye`, tagText:'popular choice', tagColor: 'purple', packSize: 30, link: "/contact-lenses/hydro-by-hubble-lenses", percent_off: hubblePricing.hydro.percentOff, cadence: 28},
      {title: "SkyHy by Hubble<sup>®</sup> Daily", feature: "most breathable", type: "skyhy", product: "Skyhy", price: `$${hubblePricing.skyhy.firstPrice}/eye`, originalPrice: `$${hubblePricing.skyhy.subPrice}/eye`, tagText:'premium pick', tagColor: 'orange', packSize: 30, link: "/contact-lenses/skyhy-by-hubble-lenses", percent_off: hubblePricing.skyhy.percentOff, cadence: 28},
    ]
  )
}
