import React, { useState, useEffect, useRef, useContext } from "react"
import "./style.scss"
import { comparisonInfo as getComparisonInfo, labels, contactLenses as getContactLenses } from "./mockedData"
import CheckedIcon from "../../../images/assets/Comparison/Checked.svg"
import UncheckedIcon from "../../../images/assets/Comparison/Unchecked.svg"
import { navigate } from "gatsby"
import PcpItem from "../../PcpItem"
import { useWindowWidth } from "../../../services/hooks"
import CartContext from "../../../context/cartContext"
import ProductTag from "../../ProductTag"
import useIsLifemart from '../../Lifemart/hooks/useIsLifemart'
import useNewRxFlowDesktop from "../../../utils/useNewRxFlowDesktop";
import useAbTest from '../../../utils/useAbTest'
import useIsMilitary from "../../Military/hooks/useIsMilitary"
import {getHubblePricing} from "../../../services/data";
import Select, {components} from "react-select";



const handleGaClick = lens => {
  let item = {}
  if (lens === "hydro") {
    item = {
      item_id: 7546170802376,
      item_name: "Hydro by Hubble Contact Lenses",
      currency: "USD",
      discount: 0,
      item_brand: "Hubble",
      item_category: "contacts",
      item_category2: "spherical",
      item_category3: "N/A",
      item_category4: "Hioxifilcon A",
      item_category5: "N/A",
      item_list_id: `Contacts`,
      item_variant: 42670112178376,
      price: 9.99,
      quantity: 1,
      index: 0,
    }
  } else {
    item = {
      item_id: 2167100637257,
      item_name: "Hubble Classic Lenses",
      currency: "USD",
      discount: 0,
      item_brand: "Hubble",
      item_category: "contacts",
      item_category2: "spherical",
      item_category3: "N/A",
      item_category4: "Methafilcon A",
      item_category5: "N/A",
      item_list_id: `Contacts`,
      item_variant: 19809451933769,
      price: 1.0,
      quantity: 1,
      index: 0,
    }
  }
  if (typeof window !== "undefined") {
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        items: [item],
      },
    })
  }
}

const OTPLensCompare = ({ isPopup, isPopupOpen, closePopup, isOtp }) => {
  const { isLensesInCart } = useContext(CartContext)
  const [show, setShow] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = useWindowWidth()
  const hubblePricing = getHubblePricing()
  const comparisonInfo = getComparisonInfo(hubblePricing)
  const isLifeMart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const contactLenses = getContactLenses(!isLifeMart && !isMilitary, hubblePricing, isOtp)

  useEffect(() => {
    setIsMobile(windowWidth < 768)
  }, [windowWidth])

  return (
        <div className="otp-lens-comparison">
          <div className="grid-section">
            <div className="table-container">
              {isMobile ? (
                <MobileTable
                  comparisonInfo={comparisonInfo}
                  isLensesInCart={isLensesInCart}
                  contactLenses={contactLenses}
                  includeNavbar={!isOtp}
                />
              ) : (
                <DesktopTable
                  comparisonInfo={comparisonInfo}
                  isLensesInCart={isLensesInCart}
                />
              )}
            </div>
          </div>
        </div>
  )
}

const HeaderRows = ({ isLensesInCart, contactLenses, includeNavbar }) => {
  return (
    <div
      className={`header-rows sticky-position`}
      id="header-rows"
    >
      {contactLenses.map(({ title, type, tagText, tagColor, link }, index) => {
        return (
          <div className="cell" data-type={type}>
            {/* {tagText && <ProductTag text={tagText} color={tagColor} />} */}
            <p className="text h6" dangerouslySetInnerHTML={{__html: title}}></p>
            {/* <button
              className="text h6 buy-now-btn"
              onClick={() => {
                handleGaClick(type)
                navigate(
                  link, {
                    rxflow: true
                  }
                )
              }}
              disabled={(isLensesInCart)}
            >
              Buy Now
            </button> */}
          </div>
        )
      })}
    </div>
  )
}

const MobileTable = ({ comparisonInfo, isLensesInCart, contactLenses, includeNavbar }) => {
  const [leftOption, setLeftOption] = useState(contactLenses.find(e => e.type === "classic"))
  const [middleOption, setMiddleOption] = useState(contactLenses.find(e => e.type === "hydro"))
  const [rightOption, setRightOption] = useState(contactLenses.find(e => e.type === "skyhy"))
  const filteredContacts = [leftOption, middleOption, rightOption]

  return (
    <div className="comparison-table">
      <div className="comparison-table-heading">Compare Our Contacts</div>
      <HeaderRows isLensesInCart={isLensesInCart} contactLenses={filteredContacts} includeNavbar={includeNavbar} />
      <div className="content-rows">
        {Object.entries(comparisonInfo).map(
          ([key, infoRow], index) => (
            <div className="cell" data-type={key} key={index}>
              <div className="text h9 neue-bold uppercase caption">
                {typeof labels[key] === "object" ? (
                  <>
                    {labels[key].text} <span>{labels[key].sub}</span>{" "}
                  </>
                ) : (
                  labels[key]
                )}
              </div>
              <div className="icons-wrapper">
                <div data-type={`${leftOption.type} ${infoRow[leftOption.type] ? "positive" : "negative"}`}>
                  {typeof infoRow[leftOption.type] === "string" && !!infoRow[leftOption.type] ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{__html: infoRow[leftOption.type]}}
                    ></div>
                  ) : !!infoRow[leftOption.type] ? (
                    <CheckedIcon/>
                  ) : (
                    <UncheckedIcon/>
                  )}
                </div>
                <div data-type={`${middleOption.type} ${infoRow[middleOption.type] ? "positive" : "negative"}`}>
                  {typeof infoRow[leftOption.type] === "string" && !!infoRow[middleOption.type] ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{__html: infoRow[middleOption.type]}}
                    ></div>
                  ) : !!infoRow[middleOption.type] ? (
                    <CheckedIcon/>
                  ) : (
                    <UncheckedIcon/>
                  )}
                </div>
                <div data-type={`${rightOption.type} ${infoRow[rightOption.type] ? "positive" : "negative"}`}>
                  {typeof infoRow[leftOption.type] === "string" && !!infoRow[rightOption.type] ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{__html: infoRow[rightOption.type]}}
                    ></div>
                  ) : !!infoRow[rightOption.type] ? (
                    <CheckedIcon/>
                  ) : (
                    <UncheckedIcon/>
                  )}
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  )
}

const DesktopTable = ({comparisonInfo, isLensesInCart}) => {
  const isLifemart = useIsLifemart()
  const isMilitary = useIsMilitary()
  const hubblePricing = getHubblePricing()
  const contactLenses = getContactLenses(!isLifemart && !isMilitary, hubblePricing)
  const isNewRxFlowDesktop = useNewRxFlowDesktop()
  const isPaidShipping = useAbTest()

  return (
    <>
      <table className="comparison-table desktop">
        <thead>
          <tr className="text h6">
            <th className="blank-cell">Compare Our Contacts</th>
            {contactLenses.map(({ title, type, tagText, tagColor, link }, index) => {
              return (
                <th data-type={type}>
                  {tagText && <ProductTag text={tagText} color={tagColor} />}
                  <p dangerouslySetInnerHTML={{__html: title}}></p>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {Object.entries(comparisonInfo).map(
            ([key, { classic, hydro, skyhy }], index) => (
              <tr key={index}>
                <th className="text h9 neue-bold uppercase" data-type="caption">
                  {typeof labels[key] === "object" ? (
                    <>
                      {labels[key].text} <span>{labels[key].sub}</span>{" "}
                    </>
                  ) : (
                    labels[key]
                  )}
                </th>
                <th className="text h7" data-type="classic">
                  {typeof classic === "string" ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{ __html: classic }}
                    ></div>
                  ) : (
                    classic ? (
                      <CheckedIcon className="checked-icon" />
                    ) : (
                      <UncheckedIcon className="unchecked-icon" />
                    )
                  )}
                </th>
                <th className="text h7" data-type="hydro">
                  {typeof hydro === "string" ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{ __html: hydro }}
                    ></div>
                  ) : (
                    hydro ? (
                      <CheckedIcon className="checked-icon" />
                    ) : (
                      <UncheckedIcon className="unchecked-icon" />
                    )
                  )}
                </th>
                <th className="text h7" data-type="skyhy">
                  {typeof skyhy === "string" ? (
                    <div
                      className="price-info"
                      dangerouslySetInnerHTML={{ __html: skyhy }}
                    ></div>
                  ) : (
                    skyhy ? (
                      <CheckedIcon className="checked-icon" />
                    ) : (
                      <UncheckedIcon className="unchecked-icon" />
                    )
                  )}
                </th>
              </tr>
            )
          )}
          {isPaidShipping &&
            <tr>
              <th className="text h9 neue-bold uppercase" data-type="caption"></th>
              <th className="text h9 asterisk" data-type="caption">
                <p className="asterisk-copy">*plus shipping & handling, and any applicable sales tax</p>
              </th>
              <th className="text h9 neue-bold uppercase" data-type="caption"></th>
              <th className="text h9 neue-bold uppercase" data-type="caption"></th>
            </tr>
          }

        </tbody>
      </table>
    </>
  )
}

export default OTPLensCompare
