import React from "react";
import Nav from "../components/Intake/Nav";
import OTPHero from "../components/Intake/OTPHero";
import AffiliateWhyHubble from "../components/Intake/AffiliateWhyHubble"
import OrderSection from "../components/Intake/OrderSection";
import ContactsCharacteristics from "../components/Intake/ContactsCharacteristicsV3";
import HomeCtaAvg from "../components/HomeCtaAvg";
import Publications from "../components/Publications";
import OTPLensCompare from "../components/Intake/OTPLensCompare";
import OTPDiscountCard from "../components/Intake/OTPDiscountCard";

import "../styles/pages/intake-hydro-skyhy.scss"

const IntakePage = () => {
  return (
    <div className="hubble-content-hydro-skyhy v3">
      <Nav isV3 />
      <OTPHero isHydroSkyhy isOtp/>
      <Publications isHomePageV2/>
      <OTPDiscountCard/>
      <ContactsCharacteristics isV3 isOtp />
      <OTPLensCompare />
      <AffiliateWhyHubble isV3 isOtp/>
      <HomeCtaAvg  isOtp/>
      <OrderSection isHydroSkyhy isIronclad={true} isV3 oneEyeTest={true} isOtp />
    </div>
  );
}

export default IntakePage
