import React from "react"
import "./styles.scss"

const OTPDiscountCard = () => {
    return (
        <div className="grid-section info-card--wrapper">
            <div className="info-card--content">
                <div className="info-card--subtitle">Buy Once, No Commitment</div>
                <p className="info-card--details">Experience all the comfort and convenience of Hubble lenses without a monthly subscription.
                            With a one-time purchase of $45.00/eye, you’ll get 90 daily lenses for 3 months of use — that’s only $1 a day!
                </p>
            </div>
            <div className="info-card--notice">
                <p className="info-card--notice-text">Use your exclusive discount code at checkout</p>
            </div>
        </div>
    )
}

export default OTPDiscountCard
