import React, { useEffect, useState } from 'react';
import { useWindowWidth } from "../../../services/hooks"

import './styles.scss';

const OTPHero = ({ isOtp }) => {
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 768)
  }, [windowWidth])

  return (
    <section className="otp-hero-wrapper">
      <div className={`otp-hero-content content-container-hero-v3 ${isOtp ? "content-container-hero-v3--otp" : ""}`}>
        <div className='otp-hero-text-block'>
          <div className='otp-hero-text-content'>
            {
              isMobile
                ? <>
                  <h1 className={`otp-hero-title  ${isOtp ? "hero-title-v3--otp" : ""}`}><>Get your contacts <br />for only $1 a day.</></h1>
                  <p className={`otp-hero-subtitle ${isOtp ? "hero-subtitle-v3--otp" : ""}`}><>Easy and Affordable</></p>
                </>
                : <>
                  <p className={`otp-hero-subtitle ${isOtp ? "hero-subtitle-v3--otp" : ""}`}><>Easy and Affordable</></p>
                  <h1 className={`otp-hero-title  ${isOtp ? "hero-title-v3--otp" : ""}`}><>Get your contacts <br />for only $1 a day.</></h1>
                </>}
                <a href={'#lenses-section'} className='otp-hero-button'>
                  Order Your Contacts Now
                </a>
          </div>
        </div>
        {
          isMobile
            ? null
            : <div className='otp-hero-image-block'>
              <div className={`otp-hero-image ${isOtp ? "image-v3--otp" : ""}`} />
            </div>
        }
      </div>
    </section>
  );
};

export default OTPHero;
